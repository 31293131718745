/**
 * author:yann
 */

/**
 * 定义返回结果
 * @param {结果} flag
 * @param {数据} data
 * @returns
 */
const result = function (flag, data) {
  return { result: flag, data: data }
}

/**
 * 判断字符串是不是空
 * @param {字符串} str
 * @returns
 */
const isEmpty = (str) => {
  try {
    if (str == null || str == "" || str === undefined) {
      return true
    } else {
      str = str.replace(/\s/g, "")
      if (str == "") {
        return true
      }
    }
    return false
  } catch (error) {
    return true
  }
}

/**
 * 首字母转小写
 * @param {字符串} str
 */
const first2LowerCase = (str) => {
  var val
  if (/[a-z]/.test(str)) {
    val = str.charAt(0).toLowerCase() + str.slice(1)
  } else {
    val = str.toLowerCase()
  }
  return val
}

/**
 * 首字母转大写
 * @param {字符串} str
 */
const first2UpperCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * josn转C#实体类
 * 1:选择注释,2:值注释,3:首字母大写,4:私有属性
 */
const josn2csharp = function (json, namespace, classname, attrArray) {
  const ann = attrArray.includes(1)
  const value_ann = attrArray.includes(2)
  const first_up = attrArray.includes(3)
  const private_attr = attrArray.includes(4)
  const private_get = attrArray.includes(5)
  const private_set = attrArray.includes(6)
  const csharpmodel = new Array()
  //默认using类   csharpmodel.push("")
  if (!isEmpty(namespace)) {
    csharpmodel.push("using System;")
    csharpmodel.push("using System.Collections.Generic;")
    csharpmodel.push("using System.Linq;")
    csharpmodel.push("using System.Text;")
    csharpmodel.push("")
    csharpmodel.push("namespace " + namespace)
    csharpmodel.push("{")
  }
  if (ann) {
    csharpmodel.push("\t/// <summary>")
    csharpmodel.push("\t/// ")
    csharpmodel.push("\t/// <summary>")
  }
  csharpmodel.push("\tpublic class " + classname)
  csharpmodel.push("\t{")
  for (let key in json) {
    csharpmodel.push("")
    //值
    var value = json[key]
    //判断值的类型
    var attr_type = "string"
    if (typeof value == "string") {
      attr_type = "string"
    } else if (typeof value == "number") {
      if (value % 1 == 0) attr_type = "int"
      else attr_type = "double"
    } else if (typeof value == "object") {
      attr_type = "List<T>"
    }
    var pri_attr
    //生成私有属性
    if (private_attr) {
      pri_attr = first2LowerCase(key)
      csharpmodel.push(`\t\tprivate ${attr_type} ${pri_attr};`)
    }
    if (ann) {
      csharpmodel.push("\t\t/// <summary>")
      if (value_ann) {
        csharpmodel.push("\t\t/// " + value)
      } else {
        csharpmodel.push("\t\t/// ")
      }
      csharpmodel.push("\t\t/// <summary>")
    }

    var pub_attr = key
    //首字母转大写
    if (first_up) {
      pub_attr = first2UpperCase(key)
    }
    if (private_attr) {
      csharpmodel.push("\t\tpublic " + attr_type + " " + pub_attr)
      csharpmodel.push("\t\t{")
      if (private_get) {
        csharpmodel.push("\t\t\tget")
        csharpmodel.push("\t\t\t{")
        csharpmodel.push("\t\t\t\treturn " + pri_attr + ";")
        csharpmodel.push("\t\t\t}")
      }
      if (private_set) {
        csharpmodel.push("\t\t\tset")
        csharpmodel.push("\t\t\t{")
        csharpmodel.push("\t\t\t\t " + pri_attr + " = value;")
        csharpmodel.push("\t\t\t}")
      }
      csharpmodel.push("\t\t}")
    } else {
      var getset = ""
      if (private_get) {
        getset += "get;"
      }
      if (private_set) {
        getset += "set;"
      }
      csharpmodel.push("\t\tpublic " + attr_type + " " + pub_attr + " { " + getset + " }")
    }
  }
  csharpmodel.push("\t}")
  if (!isEmpty(namespace)) {
    csharpmodel.push("}")
  }
  return csharpmodel.join("\r\n")
}

/**
 * josn转JAVA实体类
 */
const josn2java = function (json, javapackage, classname, attrArray) {
  const ann = attrArray.includes(1)
  const value_ann = attrArray.includes(2)
  const private_get = attrArray.includes(3)
  const private_set = attrArray.includes(4)
  const lombok = attrArray.includes(5)
  const modelclass = new Array()
  if (!isEmpty(javapackage)) {
    modelclass.push("package " + javapackage + ";")
    modelclass.push("")
  }
  if (lombok) {
    modelclass.push("import lombok.Data;")
    modelclass.push("")
    if (ann) {
      modelclass.push("/**")
      modelclass.push(" * ")
      modelclass.push(" */")
    }
    modelclass.push("@Data")
  } else {
    if (ann) {
      modelclass.push("/**")
      modelclass.push(" * ")
      modelclass.push(" */")
    }
  }

  modelclass.push("public class " + classname + " {")
  // Lombok 方式
  if (lombok) {
    for (let key in json) {
      modelclass.push("")
      //值
      var value = json[key]
      //判断值的类型
      var attr_type = "string"
      if (typeof value == "string") {
        attr_type = "String"
      } else if (typeof value == "number") {
        if (value % 1 == 0) attr_type = "Integer"
        else attr_type = "Double"
      } else if (typeof value == "object") {
        attr_type = "ArrayList<Object>"
      }
      var pri_attr = first2LowerCase(key)
      if (ann) {
        if (value_ann) {
          modelclass.push("\t // " + value)
        } else {
          modelclass.push("\t // ")
        }
      }
      modelclass.push(`\tprivate ${attr_type} ${pri_attr};`)
    }
  }
  // 非Lombok方式
  else {
    //私有方法
    for (let key in json) {
      modelclass.push("")
      //值
      var value = json[key]
      //判断值的类型
      var attr_type = "string"
      if (typeof value == "string") {
        attr_type = "String"
      } else if (typeof value == "number") {
        if (value % 1 == 0) attr_type = "Integer"
        else attr_type = "Double"
      } else if (typeof value == "object") {
        attr_type = "ArrayList<Object>"
      }
      var pri_attr = first2LowerCase(key)
      if (ann) {
        if (value_ann) {
          modelclass.push("\t // " + value)
        } else {
          modelclass.push("\t // ")
        }
      }
      modelclass.push(`\tprivate ${attr_type} ${pri_attr};`)
    }
    //格式化get set 方法
    for (let key in json) {
      modelclass.push("")
      //值
      var value = json[key]
      //判断值的类型
      var attr_type = "string"
      if (typeof value == "string") {
        attr_type = "String"
      } else if (typeof value == "number") {
        if (value % 1 == 0) attr_type = "Integer"
        else attr_type = "Double"
      } else if (typeof value == "object") {
        attr_type = "ArrayList<Object>"
      }
      var pri_attr = first2LowerCase(key)
      if (private_get) {
        modelclass.push(`\tpublic ${attr_type} get${first2UpperCase(pri_attr)}() {`)
        modelclass.push(`\t\treturn ${pri_attr};`)
        modelclass.push(`\t}`)
      }
      if (private_set) {
        modelclass.push(`\tpublic void set${first2UpperCase(pri_attr)}(${attr_type} ${pri_attr}) {`)
        modelclass.push(`\t\tthis.${pri_attr} = ${pri_attr};`)
        modelclass.push(`\t}`)
      }
    }
  }
  modelclass.push("}")

  return modelclass.join("\r\n")
}

/**
 * josn转JAVA实体类
 */
const josn2go = function (json, javapackage, classname, attrArray) {
  const ann = attrArray.includes(1)
  const value_ann = attrArray.includes(2)
  const modelclass = new Array()
  if (!isEmpty(javapackage)) {
    modelclass.push("package " + javapackage)
    modelclass.push("")
  }
  //注释
  if (ann) {
    modelclass.push("/*")
    modelclass.push("")
    modelclass.push("*/")
  }
  modelclass.push("type " + classname + " struct {")
  for (let key in json) {
    modelclass.push("")
    //值
    var value = json[key]
    //判断值的类型
    var attr_type = "string"
    if (typeof value == "string") {
      attr_type = "string"
    } else if (typeof value == "number") {
      if (value % 1 == 0) attr_type = "int"
      else attr_type = "double"
    } else if (typeof value == "object") {
      attr_type = "list<Object>"
    }
    if (value_ann) {
      modelclass.push("\t// " + value)
    } else {
      modelclass.push("\t// ")
    }
    modelclass.push(`\t${first2UpperCase(key)} ${attr_type}`)
  }
  modelclass.push("}")
  return modelclass.join("\r\n")
}

/**
 * json转xml
 */
const josn2xml = (json, rootname, attr, caseattr) => {
  // 属性作为子节点
  const children = attr === 1
  // 属性作为属性
  const attribute = attr === 2

  const cast = (str) => {
    // 全部小写
    const alllower = caseattr === 1
    // 全部大写
    const allupper = caseattr === 2
    // 首字母小写
    const firstlower = caseattr === 3
    // 首字母大写
    const secondupper = caseattr === 4
    if (alllower) {
      return str.toLowerCase()
    } else if (allupper) {
      return str.toUpperCase()
    } else if (firstlower) {
      return first2LowerCase(str)
    } else if (secondupper) {
      return first2UpperCase(str)
    } else {
      return str
    }
  }
  rootname = cast(rootname)
  const modelclass = new Array()

  //添加头信息
  modelclass.push('<?xml version="1.0" encoding="utf-8"?>')
  if (attribute) {
    var attr_str = ""
    for (let key in json) {
      var value = json[key]
      attr_str += ` ${cast(key)}="${value}"`
    }
    modelclass.push(`<${rootname}${attr_str}>`)
    modelclass.push(`</${rootname}>`)
  } else if (children) {
    modelclass.push(`<${rootname}>`)
    for (let key in json) {
      var keyitem = cast(key)
      modelclass.push(`\t<${keyitem}>${json[key]}</${keyitem}>`)
    }
    modelclass.push(`</${rootname}>`)
  }
  return modelclass.join("\r\n")
}

/*
 * 验证xml格式的正确性
 */
const xmlverify = (xml) => {
  //errorCode 0是xml正确，1是xml错误，2是无法验证
  try {
    var xmlDoc,
      errorMessage,
      errorCode = 0
    // code for IE
    if (window.ActiveXObject) {
      xmlDoc = new ActiveXObject("Microsoft.XMLDOM")
      xmlDoc.async = "false"
      xmlDoc.loadXML(xml)
      if (xmlDoc.parseError.errorCode != 0) {
        errorMessage = "错误code: " + xmlDoc.parseError.errorCode + "\n"
        errorMessage = errorMessage + "错误原因: " + xmlDoc.parseError.reason
        errorMessage = errorMessage + "错误位置: " + xmlDoc.parseError.line
        errorCode = 1
      } else {
        errorMessage = "格式正确"
      }
    }
    // code for Mozilla, Firefox, Opera, chrome, safari,etc.
    else if (document.implementation.createDocument) {
      var parser = new DOMParser()
      xmlDoc = parser.parseFromString(xml, "text/xml")
      var error = xmlDoc.getElementsByTagName("parsererror")
      if (error.length > 0) {
        if (xmlDoc.documentElement.nodeName == "parsererror") {
          errorCode = 1
          errorMessage = xmlDoc.documentElement.childNodes[0].nodeValue
        } else {
          errorCode = 1
          errorMessage = xmlDoc.getElementsByTagName("parsererror")[0].innerHTML
        }
      } else {
        errorMessage = "格式正确"
      }
    } else {
      errorCode = 0
    }
  } catch (error) {
    errorCode = 1
  }
  return [errorCode, xmlDoc, errorMessage]
}

// 缩进
const createIndent = (ind) => {
  var space = "    "

  if (isNaN(parseInt(ind))) {
    // argument is string
    space = ind
  } else {
    // argument is integer
    for (let index = 0; index < ind; index++) {
      space += " "
    }
  }

  var shift = ["\n"] // array of shifts
  for (var ix = 0; ix < 100; ix++) {
    shift.push(shift[ix] + space)
  }
  return shift
}

// xml格式化
const xmlformat = (xml, indent) => {
  var ar = xml
      .replace(/>\s{0,}</g, "><")
      .replace(/</g, "~::~<")
      .replace(/\s*xmlns\:/g, "~::~xmlns:")
      .replace(/\s*xmlns\=/g, "~::~xmlns=")
      .split("~::~"),
    len = ar.length,
    inComment = false,
    deep = 0,
    str = "",
    ix = 0,
    shift = createIndent(indent)

  for (ix = 0; ix < len; ix++) {
    // start comment or <![CDATA[...]]> or <!DOCTYPE //
    if (ar[ix].search(/<!/) > -1) {
      str += shift[deep] + ar[ix]
      inComment = true
      // end comment  or <![CDATA[...]]> //
      if (ar[ix].search(/-->/) > -1 || ar[ix].search(/\]>/) > -1 || ar[ix].search(/!DOCTYPE/) > -1) {
        inComment = false
      }
    }
    // end comment  or <![CDATA[...]]> //
    else if (ar[ix].search(/-->/) > -1 || ar[ix].search(/\]>/) > -1) {
      str += ar[ix]
      inComment = false
    }
    // <elm></elm> //
    else if (/^<\w/.exec(ar[ix - 1]) && /^<\/\w/.exec(ar[ix]) && /^<[\w:\-\.\,]+/.exec(ar[ix - 1]) == /^<\/[\w:\-\.\,]+/.exec(ar[ix])[0].replace("/", "")) {
      str += ar[ix]
      if (!inComment) deep--
    }
    // <elm> //
    else if (ar[ix].search(/<\w/) > -1 && ar[ix].search(/<\//) == -1 && ar[ix].search(/\/>/) == -1) {
      str = !inComment ? (str += shift[deep++] + ar[ix]) : (str += ar[ix])
    }
    // <elm>...</elm> //
    else if (ar[ix].search(/<\w/) > -1 && ar[ix].search(/<\//) > -1) {
      str = !inComment ? (str += shift[deep] + ar[ix]) : (str += ar[ix])
    }
    // </elm> //
    else if (ar[ix].search(/<\//) > -1) {
      str = !inComment ? (str += shift[--deep] + ar[ix]) : (str += ar[ix])
    }
    // <elm/> //
    else if (ar[ix].search(/\/>/) > -1) {
      str = !inComment ? (str += shift[deep] + ar[ix]) : (str += ar[ix])
    }
    // <? xml ... ?> //
    else if (ar[ix].search(/<\?/) > -1) {
      str += shift[deep] + ar[ix]
    }
    // xmlns //
    else if (ar[ix].search(/xmlns\:/) > -1 || ar[ix].search(/xmlns\=/) > -1) {
      str += shift[deep] + ar[ix]
    } else {
      str += ar[ix]
    }
  }

  return str[0] == "\n" ? str.slice(1) : str
}

// 增加判断对象是不是数组的方法
if (!Array.isArray) {
  Array.isArray = function (arg) {
    return Object.prototype.toString.call(arg) === "[object Array]"
  }
}

// 定义的属性
let root, xmlAttr, xmlCaseAttr
// xml 转json对象,js对象
const xml2json = (xml) => {
  /**
   * Node.ELEMENT_NODE：1
     Node.ATTRIBUTE_NODE：2
     Node.TEXT_NODE：3
  */
  // 创建一个空对象用于存储转换后的JSON数据
  var jsonObj = {}

  // 属性作为子节点
  const children = xmlAttr === 1
  // 属性作为属性
  const attribute = xmlAttr === 2

  // 全部小写
  const alllower = xmlCaseAttr === 1
  // 全部大写
  const allupper = xmlCaseAttr === 2
  // 首字母小写
  const firstlower = xmlCaseAttr === 3
  // 首字母大写
  const secondupper = xmlCaseAttr === 4

  // 根节点
  var nodes = xml

  if (children) {
    for (let index = 0; index < nodes.length; index++) {
      const ele = nodes[index]
      let nName = ele.nodeName
      // 大小写转换
      if (alllower) nName = nName.toLowerCase()
      else if (allupper) nName = nName.toUpperCase()
      else if (firstlower) nName = first2LowerCase(nName)
      else if (secondupper) nName = first2UpperCase(nName)
      // if (!isEmpty(ele.firstChild)) {
      if (ele.firstChild.nodeType == 3) {
        jsonObj[nName] = ele.firstChild.nodeValue
      } else if (ele.firstChild.nodeType == 1) {
        if (!(nName in jsonObj)) {
          jsonObj[nName] = xml2json(ele.childNodes)
        } else {
          if (Array.isArray(jsonObj[nName])) {
            jsonObj.push(xml2json(ele.childNodes))
          } else {
            var obj = jsonObj[nName]
            jsonObj = []
            jsonObj.push(obj)
            jsonObj.push(xml2json(ele.childNodes))
          }
        }
      }
      // }
    }
  } else if (attribute) {
    if (nodes.length > 1) jsonObj = []
    for (let index = 0; index < nodes.length; index++) {
      const ele = nodes[index]
      if (ele.attributes.length > 0) {
        var jsonObjTemp = {}
        for (let item of ele.attributes) {
          let nodeName = item.name
          if (alllower) nodeName = nodeName.toLowerCase()
          else if (allupper) nodeName = nodeName.toUpperCase()
          else if (firstlower) nodeName = first2LowerCase(nodeName)
          else if (secondupper) nodeName = first2UpperCase(nodeName)
          jsonObjTemp[nodeName] = item.value
        }
        if (Array.isArray(jsonObj)) {
          jsonObj.push(jsonObjTemp)
        } else {
          jsonObj = jsonObjTemp
        }
      } else {
        let nName = ele.nodeName
        if (alllower) nName = nName.toLowerCase()
        else if (allupper) nName = nName.toUpperCase()
        else if (firstlower) nName = first2LowerCase(nName)
        else if (secondupper) nName = first2UpperCase(nName)
        jsonObj[nName] = xml2json(ele.childNodes)
      }
    }
  }

  return jsonObj
}

/**
 * 导出对象
 */
export default {
  /**
   * 判断字符串是否为空
   * @param {字符串} str
   * @returns
   */
  isEmpty: (str) => {
    return isEmpty(str)
  },
  /**
   * 判断对象是不是数组
   * @param {字符串} str
   * @returns
   */
  isArray: (str) => {
    return Array.isArray(str)
  },
  /**
   * 验证是否为json格式字符串
   * @param {json字符串} json
   * @returns
   */
  jsonVerify: (json) => {
    try {
      JSON.parse(json)
      return true
    } catch (error) {
      return false
    }
  },
  /**
   * json格式化
   * @param {json字符串} json
   * @param {缩进符号} space
   * @returns
   */
  jsonFormat: (json, space) => {
    try {
      return result(true, JSON.stringify(JSON.parse(json), undefined, space))
    } catch (error) {
      return result(false, error.message)
    }
  },
  /**
   * 字符串压缩
   * @param {字符串} json字符串
   * @returns
   */
  jsonCompress: (str) => {
    return str.replace(/\s*/g, "")
  },
  /**
   * json 转义
   * @param {json字符串} json
   * @returns
   */
  jsonEscape: (json) => {
    return json.replace(/\"/g, '\\"')
  },
  /**
   * josn去除转义
   * @param {json字符串} json
   * @returns
   */
  jsonRemoveEscape: (json) => {
    return json.replace(/\\\"/g, '"')
  },
  /**
   *
   * @param {json 字符串} json
   * @param {命名空间名称} namespace
   * @param {类名} classname
   * @param {Array数组[1:选择注释,2:值注释,3:首字母大写,4:私有属性]} attrArray
   * @returns
   */
  json2CSharp: (json, namespace, classname, attrArray) => {
    try {
      return result(true, josn2csharp(JSON.parse(json), namespace, classname, attrArray))
    } catch (error) {
      return result(false, error.message)
    }
  },
  /**
   * json转java实体类
   * @param {json字符串} json
   */
  json2Java: (json, javapackage, classname, attrArray) => {
    try {
      return result(true, josn2java(JSON.parse(json), javapackage, classname, attrArray))
    } catch (error) {
      return result(false, error.message)
    }
  },
  /**
   * json转go实体类
   * @param {json字符串} json
   */
  json2Go: (json, namespace, classname, attrArray) => {
    try {
      return result(true, josn2go(JSON.parse(json), namespace, classname, attrArray))
    } catch (error) {
      return result(false, error.message)
    }
  },
  /**
   * json转xml
   * @param {json字符串} json
   * @param {根节点名称} rootname
   * @param {属性} attr
   * @param {*} caseattr
   * @returns
   */
  json2XML: (json, rootname, attr, caseattr) => {
    try {
      return result(true, josn2xml(JSON.parse(json), rootname, attr, caseattr))
    } catch (error) {
      return result(false, error.message)
    }
  },
  /**
   * 验证xml格式是否正确
   * @param {xml字符串} xml
   */
  xmlVerify: (xml) => {
    var res = xmlverify(xml)
    return res[0] == 0
  },
  /**
   * xml格式化
   * @param {xml字符串} xml
   */
  xmlFormat: (xml, space) => {
    var res = xmlverify(xml)
    if (res[0] == 0) {
      return result(true, xmlformat(xml, space))
    } else {
      return result(false)
    }
  },
  /**
   * xml压缩
   * @param {xml字符串} text
   * @returns
   */
  xmlCompress: (xml) => {
    var str = xml.replace(/\<![ \r\n\t]*(--([^\-]|[\r\n]|-[^\-])*--[ \r\n\t]*)\>/g, "").replace(/[ \r\n\t]{1,}xmlns/g, " xmlns")
    return str.replace(/>\s{0,}</g, "><")
  },
  /**
   * xml转json字符串
   * @param {xml字符串} xml
   * @param {是否包含根节点} root1
   * @param {xml属性} xmlAttr1
   * @param {大小写转换} xmlCaseAttr1
   * @returns
   */
  xml2Json: (xml, root1, xmlAttr1, xmlCaseAttr1) => {
    var res = xmlverify(xml)
    if (res[0] == 0) {
      let jsonObj = {}
      try {
        root = root1
        var element = res[1].childNodes
        if (!root) {
          element = res[1].childNodes[0].childNodes
        }
        xmlAttr = xmlAttr1
        xmlCaseAttr = xmlCaseAttr1
        jsonObj = xml2json(element)
      } catch (error) {}
      return result(true, JSON.stringify(jsonObj, undefined, "\t"))
    } else {
      return result(false)
    }
  },
}
