const CryptoJS = require("crypto-js")

/**
 * 导出对象
 */
export default {
  md5: (msg) => {
    return CryptoJS.MD5(msg).toString()
  },
  sha1: (msg) => {
    return CryptoJS.SHA1(msg).toString()
  },
  sha3: (msg, length) => {
    return CryptoJS.SHA3(msg, { outputLength: length }).toString()
  },
  sha256: (msg) => {
    return CryptoJS.SHA256(msg).toString()
  },
  sha512: (msg) => {
    return CryptoJS.SHA512(msg).toString()
  },
  ripemd160: (msg) => {
    return CryptoJS.RIPEMD160(msg).toString()
  },
}
