import codetool from "@/scripts/codetool"
import secure from "@/scripts/secure"
/**
 * 全局对象方法
 */

export default {
  codetool,
  secure,
  webcode: "鲁ICP备2021024267号-2",
}
