<template>
  <n-layout class="layout">
    <n-layout-header class="header"> <img src="../assets/codetool.png" class="codetool" @click="itemClick('/')" /> </n-layout-header>
    <router-view> </router-view>
    <n-layout-footer class="footer" position="absolute">
      <a href="http://beian.miit.gov.cn" target="_blank" class="beian">{{ webcode }}</a>
    </n-layout-footer>
  </n-layout>
</template>
<script setup>
import { getCurrentInstance, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
const router = useRouter()

// 全局对象
const app = getCurrentInstance().appContext.config.globalProperties.app
const webcode = app.webcode
// 点击跳转路由
const itemClick = (route) => {
  router.push(route)
}
</script>
<style scoped>
.layout {
  height: 100vh;
}
.header {
  background-color: white;
  height: 5vh;
}
.header .codetool {
  height: 5vh;
  margin-left: 10px;
}
.content-left {
  height: 92vh;
}
.content-center {
  height: 92vh;
}
.footer {
  line-height: 3vh;
  text-align: center;
  background-color: white;
}
.beian {
  color: black;
  text-decoration: none;
}
</style>
