import { createApp } from "vue"
import App from "./App.vue"
import globalapp from "@/scripts/global"
import router from "./route/router"
import { create, NModal, NButton, NInput, NMenu, NLayout, NLayoutHeader, NLayoutContent, NLayoutSider, NLayoutFooter, NIcon, NGrid, NGridItem, NSwitch, NRadioGroup, NRadio, NRadioButton, NButtonGroup, NSpace, NMessageProvider, NDropdown, NCheckbox, NCheckboxGroup, NDivider, NInputGroup, NInputGroupLabel, NSelect, NTabs, NTabPane, NColorPicker, NUpload, NUploadDragger, NText, NImage, NInputNumber, NScrollbar, NVirtualList } from "naive-ui"

const naive = create({
  components: [NButton, NInput, NMenu, NLayout, NLayoutHeader, NCheckbox, NLayoutContent, NLayoutSider, NLayoutFooter, NIcon, NGrid, NGridItem, NSpace, NMessageProvider, NDropdown, NModal, NCheckboxGroup, NButtonGroup, NSwitch, NRadioGroup, NRadioButton, NRadio, NDivider, NInputGroup, NInputGroupLabel, NSelect, NTabs, NTabPane, NColorPicker, NUpload, NUploadDragger, NText, NImage, NInputNumber, NScrollbar, NVirtualList],
})

const app = createApp(App)
// 注入 navie 对象
app.use(naive)
//注入路由
app.use(router)
//注册全局对象
app.config.globalProperties.app = globalapp
//挂载节点
app.mount("#app")
