import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: "/",
    redirect: "/home/jsonformat",
  },
  {
    path: "/home",
    component: () => import("@/pages/menus"),
    children: [
      {
        path: "",
        component: () => import("@/modules/json/jsonformat"),
      },
      {
        path: "jsonformat",
        component: () => import("@/modules/json/jsonformat"),
      },
      {
        path: "jsonmodel",
        component: () => import("@/modules/json/jsonmodel"),
      },
      {
        path: "jsonxml",
        component: () => import("@/modules/json/jsonxml"),
      },
      {
        path: "xmlformat",
        component: () => import("@/modules/xml/xmlformat"),
      },
      {
        path: "xmlmodel",
        component: () => import("@/modules/xml/xmlmodel"),
      },
      {
        path: "xmljson",
        component: () => import("@/modules/xml/xmljson"),
      },
      {
        path: "expression",
        component: () => import("@/modules/regular/expression"),
      },
      {
        path: "md5sha",
        component: () => import("@/modules/cryptogram/md5sha"),
      },
      {
        path: "qrcode",
        component: () => import("@/modules/qrcode/qrcode"),
      },
      {
        path: "colors",
        component: () => import("@/modules/utils/colors"),
      },
      {
        path: "wordart",
        component: () => import("@/modules/text/asciiwordart"),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
